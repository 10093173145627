import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'
import moment from 'moment';
import DualListBox from "dual-listbox-vue";

Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin,
        DualListBox
    },
    data: function() {
        return {
            pageTitle: this.$t('traitement.document_vente.liste_facture'),
            base_url: Vue.BASE_URL,
            recap_total_periodique: [],
            type_document: "",
            filtreDate: {
                dateDebut: "",
                dateFin: ""
            },
            dateOptions: {
                format: 'DD/MM/YYYY',
            },
            vuetable: {
                link: this.BASE_URL + "/factures/fetchall",
                moreParams: {},
                fields: [
                    // {
                    //     name: VuetableFieldCheckbox,
                    //     titleClass: 'text-center',
                    //     dataClass: 'text-center'
                    // },
                    {
                        name: 'date_facture_formatted',
                        title: this.$t('traitement.document_vente.date'),
                        sortField: 'date_facture',
                        dataClass: 'text-center',
                        width: '80px',
                        titleClass: 'text-center',
                    },
                    {
                        name: 'nom_magasin',
                        title: this.$t('traitement.document_vente.magasin'),
                        dataClass: 'text-left',
                        sortField: 'nom_magasin',
                    },
                    {
                        name: 'num_facture',
                        title: this.$t('traitement.document_vente.facture'),
                        dataClass: 'text-center',
                        sortField: 'num_facture',
                    },
                    {
                        name: 'nom_client',
                        title: this.$t('traitement.document_vente.client'),
                        dataClass: 'text-left',
                        sortField: 'nom_client',
                    },
                    {
                        name: 'total_montant_avec_remise_detail',
                        title: this.$t('traitement.document_vente.montant_total'),
                        dataClass: 'text-right',
                        sortField: 'total_montant_avec_remise_detail',
                        width: "140px",
                        titleClass: 'text-center',
                    },
                    {
                        name: 'remise',
                        title: 'Remise',
                        dataClass: 'text-right',
                        sortField: this.$t('traitement.document_vente.remise'),
                        width: "140px",
                        titleClass: 'text-center',
                    },
                    {
                        name: 'total_a_payer',
                        title: this.$t('traitement.document_vente.total_a_payer'),
                        dataClass: 'text-right text-bold',
                        sortField: 'total_a_payer',
                        width: "140px",
                        titleClass: 'text-center',
                    },
                    {
                        name: 'acompte',
                        title: this.$t('traitement.document_vente.acompte'),
                        dataClass: 'text-right text-bold',
                        sortField: 'acompte',
                        width: "140px",
                        titleClass: 'text-center',
                    },
                    {
                        name: 'statut_paiement',
                        title: this.$t('traitement.document_vente.statut'),
                        dataClass: 'text-center',
                        sortField: 'statut_paiement',
                        titleClass: "text-center",
                        width: "130px",
                    },
                    {
                        name: 'date_heure_enregistrement',
                        title: this.$t('traitement.document_vente.date_enregistrement'),
                        dataClass: 'text-center',
                        titleClass: 'text-center',
                        sortField: 'date_heure_enregistrement',
                        width: "100px",
                    },

                    {
                        name: 'actions',
                        title: '-',
                        width: "40px",
                        dataClass: "text-center",
                        titleClass: 'text-center',
                    }

                ],
                sortOrder: [
                    { field: 'date_facture', direction: 'asc' }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-bordered table-hovered',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars fa-sm',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
            exportexcel: {
                json_fields: {
                    'Date': 'date_facture_formatted',
                    'Numero': 'num_facture',
                    'Client': 'nom_client',
                    'Total Montant HT': 'total_ht',
                    'Total Montant TVA': 'total_tva',
                    'Total Montant TTC': 'total_ttc',
                    'Total Remise': 'total_remise',
                    'Total Montant A payer': 'total_a_payer',
                    'Acompte': 'acompte',
                    'Commentaire': 'commentaire',
                    'Date Heure Enregistrement': 'date_heure_enregistrement'
                },
                json_data: [],
                json_meta: [
                    [{
                        'key': 'charset',
                        'value': 'utf-8'
                    }]
                ],
            },
            datepicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            motCle: "",
            crudform: {
                id: "",
                num_facture: "",
                date_facture_formatted: moment().format('DD/MM/YYYY'),
                date_echeance_facture_formatted: moment().format('DD/MM/YYYY'),
                clients_id: "",
                magasin_id: "",
                commentaire: "",
                total_tva: "",
                total_ht: "",
                total_ttc: "",
                total_remise: "",
                total_a_payer: "",
                acompte: "",
                from_bl: 0,
                type_facture: "FACTURE",
                type_client: "INCONNU",
                details: [],
                degats: [],
                bon_livraison_id: [],
                montant_recu: "",
                montant_rendu: "",
                reference: "",
                mode_paiement: "ESPECE",
                date_echeance: moment().format('DD/MM/YYYY'),
                remarques: "",
                total_remise_detail: "",
                total_montant_avec_remise_detail: "",
                nom_client_inconnu: "",
                not_editable: false,
            },
            crudmodaltitle: this.$t('traitement.document_vente.modal.nouveau_facture'),
            listdata: {
                familles: [],
                articles: [],
                clients: [],
                magasins: [],
                bonlivaisons: [],
                users: [],
                magasin_defaut: "",
            },
            criterearticle: {
                famille: "",
                search: ""
            },
            client: {},
            showDetail: true,
            listchoixbl: {
                source: [],
                destination: []
            },
            point_de_ventes: [],
            point_de_ventes_id: "",
            isReadonly: false,
            isShowButton: true,
            users_id: "",
            avec_degats: false,
            vente_en_gros: false,
            has_echeance_facture: false,
            has_echeance: false,

        }
    },
    methods: {
        onRowClass(dataItem, index) {
            if (index == "") console.log("");
            console.log(index);
            // console.log(dataItem);
            // console.log("****************************");
            var retour = "";
            retour = dataItem.statut_paiement == "NON PAYE" && dataItem.type_facture != 'FACTURE PROFORMA' ?
                'text-danger' :
                ''

            return retour;
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        editRow(rowData) {
            this.crudmodaltitle = this.$t('traitement.document_vente.modal.editer_facture');
            //alert("You clicked edit on" + JSON.stringify(rowData))
            this.crudform.details = [];
            this.crudform.degats = [];
            axios.get(this.BASE_URL + "/factures/get/" + rowData.id).then(response => {
                console.log(response.data[0]);
                response.data[0].montant_recu = "";
                response.data[0].montant_rendu = "";
                response.data[0].reference = "";
                response.data[0].mode_paiement = "ESPECE";
                response.data[0].remarques = "";
                response.data[0].type_client = response.data[0].clients_id > 1 ? "CLIENT" : "INCONNU";
                response.data[0].not_editable = false;
                response.data[0].type_facture = (response.data[0].type_facture == "FACTURE" && response.data[0].statut_mvt == 0) ? "AVOIR CLIENT" : response.data[0].type_facture;
                this.has_echeance_facture = (response.data[0].date_echeance_facture == "" || response.data[0].date_echeance_facture == null) ? false : true;

                if (response.data[0].degats.length > 0) {
                    this.avec_degats = true;
                } else {
                    this.avec_degats = false;
                }
                // var date_enr = moment(response.data[0].date_heure_enregistrement).format('DD/MM/YYYY');
                // if (date_enr < moment().format('DD/MM/YYYY')) {
                //     response.data[0].not_editable = true;
                // }
                this.crudform = response.data[0];
                this.updateMontant();
            });
            this.openModal();
        },
        deleteRow(rowData) {
            //alert("You clicked delete on" + JSON.stringify(rowData));
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Supprimer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        axios.post(that.BASE_URL + "/factures/delete/" + rowData.id).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log(response.data.message);
                                that.setFilter();
                                Vue.$toast.open({
                                    message: response.data.message,
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                })

        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.point_de_ventes_id = this.point_de_ventes_id;
            this.vuetable.moreParams.criteriacol = this.criteriacol;
            this.vuetable.moreParams.type_document = this.type_document;
            this.vuetable.moreParams.startDate = this.filtreDate.dateDebut;
            this.vuetable.moreParams.endDate = this.filtreDate.dateFin;
            this.vuetable.moreParams.users_id = this.$parent.role_user == "VENDEUR" ? this.$parent.id_user : this.users_id;
            Vue.nextTick(() => this.$refs.vuetable.refresh());

            var that = this;
            axios.post(that.BASE_URL + "/factures/fetchmontanttotal", this.vuetable.moreParams).then(function(response) {
                console.log(response);
                that.recap_total_periodique = response.data;
            });
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.vuetable.moreParams.filter = this.motCle;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        loadPointDeVente() {
            axios.get(this.BASE_URL + "/pointvente/getall/").then(response => {
                this.point_de_ventes = response.data;
            });
        },
        onExport() {

            this.exportexcel.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        openModal() {
            this.$bvModal.show("myCustomModal");
        },
        closeModal() {
            this.$bvModal.hide("myCustomModal");
        },
        addRow() {
            this.crudmodaltitle = this.$t('traitement.document_vente.modal.nouveau_facture');
            this.showDetail = true;
            this.avec_degats = false;
            this.has_echeance_facture = false;
            this.vente_en_gros = false;

            this.crudform = {
                id: "",
                num_facture: "",
                date_facture_formatted: moment().format('DD/MM/YYYY'),
                date_echeance_facture_formatted: moment().format('DD/MM/YYYY'),
                clients_id: "",
                magasin_id: "",
                commentaire: "",
                total_tva: "",
                total_ht: "",
                total_ttc: "",
                total_remise: "",
                total_a_payer: "",
                acompte: "",
                from_bl: 0,
                type_client: "INCONNU",
                details: [],
                degats: [],
                bon_livraison_id: [],
                date_echeance: moment().format('DD/MM/YYYY'),
                montant_recu: "",
                montant_rendu: "",
                reference: "",
                mode_paiement: "ESPECE",
                remarques: "",
                nom_client_inconnu: "",
                not_editable: false,
            };


            if (this.$route.name == "Factures") {
                this.crudform.type_facture = "FACTURE";
            } else if (this.$route.name == "Facturesproformat") {
                this.crudform.type_facture = "FACTURE PRO-FORMAT";
            }

            if (localStorage.type_facture) {
                this.crudform.type_facture = localStorage.type_facture;
            } else {
                this.crudform.type_facture = "FACTURE";
            }

            if (this.listdata.magasin_defaut != '' && this.listdata.magasin_defaut != null) {
                this.crudform.magasin_id = this.listdata.magasin_defaut + '';
            }
            if (!(this.crudform.magasin_id > 0)) {
                if (this.listdata.magasins.length == 1) {
                    this.crudform.magasin_id = this.listdata.magasins[0].id;
                }
            }
            this.getNumeroFacture();
            this.openModal();
        },
        onSubmit() {

            this.openConfirmModal();
            /*
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Continuer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = this.crudform.id == "" ? "/factures/addaction" : "/factures/editaction";
                        axios.post(link, $("#formulaire").serialize()).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                that.closeModal();
                                that.setFilter();
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
            */
        },
        submitForm: function() {
            this.closeConfirmModal();

            var that = this;
            var link = this.crudform.id == "" ? this.BASE_URL + "/factures/addaction" : this.BASE_URL + "/factures/editaction";
            axios.post(link, $("#formulaire").serialize()).then(function(response) {
                console.log(response);
                //this.message_reponse = response.data.msg;
                if (response.data.error === "") {
                    console.log("Enregistrement effectué avec succès!");
                    if (!(that.crudform.id > 0)) {
                        var row = {
                            id: response.data.id
                        };
                        that.editRow(row);
                    }
                    // that.closeModal();
                    that.setFilter();
                    Vue.$toast.open({
                        message: 'Enregistrement effectué avec succès!',
                        type: 'success',
                        position: 'top-right'
                            // all of other options may go here
                    });
                } else {
                    console.log("Erreur");
                    Vue.$toast.open({
                        message: response.data.error,
                        type: 'error',
                        position: 'top-right'
                            // all of other options may go here
                    });
                }
            });
        },
        fetchData: function() {
            axios.get(this.BASE_URL + "/factures/fetchdata").then(response => {
                this.listdata.familles = response.data.familles;
                this.listdata.clients = response.data.clients;
                this.listdata.magasins = response.data.magasins;
                this.listdata.users = response.data.users;
                this.listdata.magasin_defaut = response.data.magasin_defaut;

                if (this.listdata.magasin_defaut != '' && this.listdata.magasin_defaut != null) {
                    this.crudform.magasin_id = this.listdata.magasin_defaut + '';
                }
            });
        },
        setCritereArticleFilter: function() {
            axios.get(this.BASE_URL + "/articles/fetchlimitted", {
                params: {
                    famille: this.criterearticle.famille,
                    search: this.criterearticle.search,
                    magasin: this.crudform.magasin_id
                }
            }).then(response => {

                this.listdata.articles = response.data;
            });
        },
        resetCritereArticleFilter: function() {
            this.criterearticle.famille = "";
            this.criterearticle.search = "";


            this.setCritereArticleFilter();
        },
        pushrow: function(articleData) {
            console.log(articleData)
            var exist = false;
            if (!this.avec_degats) {
                for (var i = 0; i < this.crudform.details.length; i++) {
                    var item = this.crudform.details[i];
                    if (item.articles_id == articleData.articles_id) {
                        Vue.$toast.open({
                            message: "Cet article a déjà été choisit",
                            type: 'warning',
                            position: 'top-right'
                        });
                        exist = true;
                        break;
                    }
                }
            } else {
                for (i = 0; i < this.crudform.degats.length; i++) {
                    item = this.crudform.degats[i];
                    if (item.articles_id == articleData.articles_id) {
                        Vue.$toast.open({
                            message: "Cet article a déjà été choisit",
                            type: 'warning',
                            position: 'top-right'
                        });
                        exist = true;
                        break;
                    }
                }
            }


            if (!exist) {
                if (!this.avec_degats) {
                    this.crudform.details.push({
                        articles_id: articleData.articles_id,
                        ref_article: articleData.ref_article,
                        designation: articleData.designation,
                        pau_ht: this.vente_en_gros ? articleData.prix_vente_gros : articleData.pu_vente,
                        tva: articleData.tva,
                        pau_ttc: this.vente_en_gros ? articleData.prix_vente_gros : articleData.pu_vente,
                        prix_revient: articleData.prix_revient,
                        quantite: 1,
                        remise: "",
                        montant_ht: "",
                        montant_tva: "",
                        montant_ttc: "",
                        montant_a_payer: "",
                    });
                } else {
                    this.crudform.degats.push({
                        articles_id: articleData.articles_id,
                        ref_article: articleData.ref_article,
                        designation: articleData.designation,
                        quantite: ""
                    });
                }

            }
            this.updateMontant();
        },
        delRow: function(i) {
            this.crudform.details.splice(i, 1);
            this.updateMontant();
        },
        delRowDegats: function(i) {
            this.crudform.degats.splice(i, 1);
            this.updateMontant();
        },
        updateHT: function(i) {
            //console.log("Update HT");
            var item = this.crudform.details[i];
            if (item.pau_ttc == '' || item.pau_ttc == null) {
                item.pau_ttc = 0;
            }
            if (item.tva == '' || item.tva == null) {
                item.tva = 0;
            }
            console.log(item);
            this.crudform.details[i].pau_ht = parseFloat(item.pau_ttc) / (parseFloat(item.tva) / 100 + 1);
            console.log(this.crudform.details[i].pau_ht);
            this.updateMontant();

        },
        updateTTC: function(i) {
            //console.log("Update TTC");
            var item = this.crudform.details[i];
            if (item.pau_ht == '' || item.pau_ht == null) {
                item.pau_ht = 0;
            }
            if (item.tva == '' || item.tva == null) {
                item.tva = 0;
            }
            console.log(item);
            this.crudform.details[i].pau_ttc = parseFloat(item.pau_ht) * (parseFloat(item.tva) / 100 + 1);
            console.log(this.crudform.details[i].pau_ttc);
            this.updateMontant();

        },
        updateMontant: function() {
            this.crudform.total_a_payer = 0;
            this.crudform.total_ht = 0;
            this.crudform.total_ttc = 0;
            this.crudform.total_tva = 0;
            this.crudform.total_remise = 0;


            this.crudform.total_ht = 0;
            this.crudform.total_ttc = 0;
            this.crudform.total_remise_detail = 0;
            this.crudform.total_montant_avec_remise_detail = 0;
            this.crudform.total_tva = 0;

            //console.log("CHANGE: ");

            var montant_a_payer = 0;
            for (var i = 0; i < this.crudform.details.length; i++) {
                if (this.crudform.details[i].pau_ht == null || this.crudform.details[i].pau_ht == '') {
                    this.crudform.details[i].pau_ht = 0;
                }
                if (this.crudform.details[i].quantite == null || this.crudform.details[i].quantite == '') {
                    this.crudform.details[i].quantite = 0;
                }
                this.crudform.details[i].pau_ttc = parseFloat(this.crudform.details[i].pau_ht) * (parseFloat(this.crudform.details[i].tva) / 100 + 1);
                this.crudform.details[i].montant_ht = parseFloat(this.crudform.details[i].pau_ht) * parseFloat(this.crudform.details[i].quantite);
                this.crudform.details[i].montant_ttc = parseFloat(this.crudform.details[i].pau_ttc) * parseFloat(this.crudform.details[i].quantite);
                this.crudform.details[i].montant_tva = parseFloat(this.crudform.details[i].montant_ttc) - parseFloat(this.crudform.details[i].montant_ht);
                if (this.crudform.details[i].remise == '' || this.crudform.details[i].remise == null) {
                    this.crudform.details[i].remise = 0;
                }
                this.crudform.details[i].montant_a_payer = this.crudform.details[i].montant_ttc - this.crudform.details[i].remise;

                // Mettre a jour les totaux
                // this.crudform.total_ht += this.crudform.details[i].montant_ht;
                // this.crudform.total_ttc += this.crudform.details[i].montant_ttc;
                // this.crudform.total_tva += this.crudform.details[i].montant_tva;
                // this.crudform.total_remise += this.crudform.details[i].remise;
                montant_a_payer += this.crudform.details[i].montant_a_payer;

                this.crudform.total_ht += this.crudform.details[i].montant_ht;
                this.crudform.total_tva += this.crudform.details[i].montant_tva;
                this.crudform.total_ttc += this.crudform.details[i].montant_ttc;
                this.crudform.total_remise_detail += this.crudform.details[i].remise;
                this.crudform.total_montant_avec_remise_detail += this.crudform.details[i].montant_a_payer;

            }
            // var remise = 0;
            // if (this.crudform.remise != '' && this.crudform.remise != null) {
            //     remise = this.crudform.remise;
            // }
            //console.log(this.crudform.total_remise);
            //console.log(remise);

            this.crudform.total_a_payer = montant_a_payer - parseFloat(this.crudform.remise);
            if (this.crudform.id == '' || this.crudform.id == null || this.crudform.id == undefined) {
                this.crudform.acompte = this.crudform.total_a_payer;
            }

            // this.crudform.total_a_payer = montant_a_payer - parseFloat(remise);
            // if (this.crudform.id == '' || this.crudform.id == null || this.crudform.id == undefined) {
            //     this.crudform.acompte = this.crudform.total_a_payer;
            // }

        },
        getClient: function(id_client) {
            if (id_client == '' || id_client == null) {
                this.client = {};
            } else {
                axios.get(this.BASE_URL + "/clients/get/" + id_client).then(response => {
                    this.client = response.data[0];

                });
            }

        },
        getNumeroFacture: function() {
            this.onChangeTypeFacture();
            var link = (this.crudform.type_facture == "FACTURE" || this.crudform.type_facture == "AVOIR CLIENT") ? this.BASE_URL + "/factures/getnumerofacture" : this.crudform.type_facture == "NOTE D'ACHAT" ? this.BASE_URL + "/factures/getnumeronotedachat" : this.BASE_URL + "/factures/getnumerofactureproformat";
            // if (this.crudform.type_facture == "FACTURE" || this.crudform.type_facture == "AVOIR CLIENT" || this.crudform.type_facture == "NOTE D'ACHAT") {
            //     this.crudform.acompte = this.crudform.total_a_payer;
            // }

            axios.get(link).then(response => {
                this.crudform.num_facture = response.data.numero;
            });
        },
        marqueBondelivraison: function(facture_id) {
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment marquer ce bon de livraison comme livré ?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Continuer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        axios.post(that.BASE_URL + "/factures/marquelivre/" + facture_id).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log(response.data.message);
                                that.setFilter();
                                Vue.$toast.open({
                                    message: response.data.message,
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                });
            axios.get(this.BASE_URL + "/factures/getnumerofactures").then(response => {
                this.crudform.num_facture = response.data.numero;
            });
        },
        openBlModal() {
            this.fetchBlParClient(this.crudform.clients_id);
            this.$bvModal.show("dualListModal");
        },
        closeBlModal() {
            this.$bvModal.hide("dualListModal");
        },
        openConfirmModal() {
            this.crudform.montant_recu = "";
            this.crudform.mode_paiement = "ESPECE";
            this.updateRestant();
            this.$bvModal.show("confirmationModal");
        },
        closeConfirmModal() {
            this.$bvModal.hide("confirmationModal");
        },
        onChangeList: function({ source, destination }) {
            this.listchoixbl.source = source;
            this.listchoixbl.destination = destination;
            console.log("Change list")
        },
        fetchBlParClient: function(id_client) {

            axios.get(this.BASE_URL + "/bonlivraison/fetchbyclient/" + id_client + "/0").then(response => {
                console.log(response.data);
                this.listchoixbl.source = response.data;
                this.listchoixbl.destination = [];
            });
        },
        selectBL: function() {
            var that = this;
            console.log(that.listchoixbl.destination);
            if (that.listchoixbl.destination.length > 0) {
                this.$bvModal.msgBoxConfirm('Voulez-vous vraiment generer la facture ?', {
                        title: 'Confirmation',
                        size: 'md',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        okTitle: 'Continuer',
                        cancelTitle: 'Annuler',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then(value => {
                        if (value == true) {
                            var ids = [];
                            for (var i = 0; i < that.listchoixbl.destination.length; i++) {
                                ids.push(that.listchoixbl.destination[i].id);
                            }

                            axios.get(this.BASE_URL + "/bonlivraison/fetchdetailsbyids", {
                                params: { ids: ids }
                            }).then(function(response) {
                                console.log(response);

                                if (response.data.details.length > 0) {
                                    that.crudform.details = response.data.details;
                                    that.crudform.remise = response.data.remise;
                                    that.crudform.num_bon_livraison = response.data.num_bon_livraison;
                                    that.crudform.from_bl = 1;
                                    that.crudform.bon_livraison_id = ids;

                                    that.closeBlModal();
                                    that.updateMontant();
                                } else {
                                    console.log("Erreur");
                                    Vue.$toast.open({
                                        message: "Une erreur est survenue lors de l'operation.</br>Veuillez reesayer ulterieurement",
                                        type: 'error',
                                        position: 'top-right'
                                            // all of other options may go here
                                    });
                                    that.crudform.details = [];
                                    that.crudform.bon_livraison_id = [];
                                }
                            });
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            } else {
                Vue.$toast.open({
                    message: "Veuillez choisir les bons de livraison à transformer en facture!",
                    type: 'warning',
                    position: 'top-right'
                        // all of other options may go here
                });
            }

        },

        onChangeClient: function() {

            console.log("CHANGE CLIENT");
            this.getClient(this.crudform.clients_id);
            this.crudform.from_bl = 0;
            this.initList();
        },
        onChangeMagasin: function() {
            this.setCritereArticleFilter();
            this.crudform.from_bl = 0;
            this.initList();
        },
        onClickInconnu: function() {
            this.crudform.type_client = 'INCONNU';
            this.crudform.nom_client_inconnu = "";
            this.crudform.clients_id = 0;
            this.initList();
        },
        onClickClient: function() {
            this.crudform.type_client = 'CLIENT';
            this.crudform.nom_client_inconnu = "";
            console.log(this.crudform);
        },
        initList: function() {
            this.crudform.details = [];
            this.crudform.remise = 0;
            this.crudform.num_bon_livraison = "";
            this.crudform.bon_livraison_id = [];
        },
        updateRestant: function() {
            var recu = Number(this.crudform.montant_recu) == "" ? Number(this.crudform.acompte) : Number(this.crudform.montant_recu);
            this.crudform.montant_rendu = recu - Number(this.crudform.acompte);
        },
        onChangeTypeFacture: function() {

            localStorage.type_facture = this.crudform.type_facture;
        }

    },
    computed: {
        articlesList: function() {
            var that = this;
            return this.listdata.articles.filter(function(article) {
                if (article.ref_article.toLowerCase().includes(that.criterearticle.search.toLowerCase()) || article.designation.toLowerCase().includes(that.criterearticle.search.toLowerCase())) {
                    return true;
                }
                return false;
            });
        }
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);

        this.motCle = "";
        this.criteriacol = "";

        if (this.$route.name == "Factures") {
            this.crudform.type_facture = "FACTURE";
            this.vuetable.link = this.BASE_URL + "/factures/fetchall";
        } else if (this.$route.name == "Facturesproformat") {
            this.crudform.type_facture = "FACTURE PRO-FORMAT";
            this.vuetable.link = this.BASE_URL + "/factures/fetchallproformat";
        }
        var currentDateWithFormat = moment().format('DD/MM/YYYY');
        this.filtreDate.dateDebut = currentDateWithFormat;
        this.filtreDate.dateFin = currentDateWithFormat;

        this.loadPointDeVente();
        this.fetchData();
        this.setCritereArticleFilter();

        this.setFilter();

    },
    watch: {
        'criterearticle.famille': function() {
            this.setCritereArticleFilter();
        },

        "crudform.date_facture_formatted": function() {
            if (moment().format('DD/MM/YYYY') === this.crudform.date_facture_formatted) {
                this.isReadonly = false;
                this.isShowButton = true;
            } else {
                this.isReadonly = true;
                this.isShowButton = false;
                if (this.$parent.role_user == 'dévéloppeur' || this.$parent.role_user == 'SUPER ADMIN') {
                    this.isReadonly = false;
                    this.isShowButton = true;
                }
            }
            // alert(moment().format('DD/MM/YYYY') + " ---- " + this.crudform.date_facture_formatted);
        }
    },
    mounted: function() {
        console.log(this.$route.name);


        if (this.$route.params.type == 'add') {
            this.addRow();
        }
    }
}